import './App.css'; 
import Container from './Container'; 

function App() {
  return (
 <Container />
  );
}

export default App;
