import { css, styled } from "styled-components";
import SecondaryPicture from "../../images/Photo_02.jpg";
import ThirdPicture from "../../images/Photo_03.png";
import ThirdPictureSmall from "../../images/Photo_03_small.png";
import FourthPicture from "../../images/Photo_04.png";
import FourthPictureSmall from "../../images/Photo_04_small.png";
import FifthPicture from "../../images/Photo_05.png";
import FifthPictureSmall from "../../images/Photo_05_small.png";
import { ReactComponent as VectorRight } from "../../images/VectorRight2.svg";

export const DetailsWrapper = styled.section`
  display: grid;
  place-items: center;
  padding-top: 84px;
  width: 100%;
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
  @media (max-width: 750px) {
    padding-top: 0px;
  }
  @media (min-width: 361px) and (max-width: 475px) {
    justify-content: center;
    
  }
  @media(max-width:475px){
    padding-top: 84px;
  }
`;

export const PictureWrapper = styled.div`
  max-width: 1092px;
  max-height: 615px;
`;

export const PictureTwo = styled.div`
  background: url(${SecondaryPicture});
  max-width: 1096px;
  width: 100%;
  max-height: 615px;
  height: 100%;
  aspect-ratio: 1 / 1;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 750px) {
    width: 320px;
    height: 200px;
  }
  @media (min-width: 361px) and (max-width: 475px) {
    // margin: 0 20px;
    min-width: 320px;
    min-height: 200px;
    // width: 100%;
    // height: 100%;
    background-size: contain;
    aspect-ratio: auto;
    margin: 0 10px;
    // padding-top:45px;
  }
  @media (min-width: 476px) and (max-width: 750px) {
    background-size: contain;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
    aspect-ratio: 16/9;
    min-width: 436px;
    // width: 100%;
    height:auto;
  }
  @media (min-width: 751px) and (max-width: 1100px) {
    background-size: contain;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
    aspect-ratio: 16/9;
  }
  
`;
export const PictureDiv = styled.div`
@media (min-width: 476px) and (max-width: 750px) {
  width:100%;
  min-width: 436px;
  // margin: 0 20px;
  // height:auto;
  background-size: cover;
  }
`;

export const DetailsContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1092px;
  width: 100%;
  height: 100%;
  margin-left:20px;
  margin-right:20px;

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    width:320px;
    margin-left:0;
    margin-right:0;
  }
  @media (min-width: 751px) and (max-width: 1100px) {
    gap:15px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media(min-width:476px)and (max-width:750px){
    width:100%;
    
  }
`;

export const ColumnFirst = styled.div`
  margin: 34px 0 10px;
  @media (min-width: 751px) and (max-width: 1100px) {
    margin-left:20px;
  }
`;

export const ColumnSecond = styled.div`
  margin: 211px 0 10px;
  display: flex;
  
  @media (min-width: 751px) and (max-width: 1100px) {
    margin-right:20px;
    justify-content:flex-end;
  }
`;

export const BorderWrap = styled.div`
  max-width: 539px;
  // width: 100%;
  height: 100%;

  @media (max-width: 475px) {
    width: 320px;
    // margin-left: 20px;
  }
  @media(min-width:476px)and (max-width:750px){
    width:100%;
    margin:0 20px;
    max-width:750px;
  }

`;

export const DetailsTitle = styled.h1`
  font-weight: 500;
  font-size: 36px;
  line-height: 39px;
  color: #181818;

  ${({ isSecond }) =>
    isSecond &&
    css`
      margin-top: 179px;

      @media (max-width: 750px) {
        margin-top: 48px;
      }
    `}

  @media (max-width: 750px) {
    font-size: 24px;
    line-height: 30px;
    margin-top: 48px;
  }
  
`;

export const Description = styled.p`
  max-width: 539px;
  width: 100%;
  font-size: 18px;
  font-style: normal;
  color: #474945;
  line-height: 24px;
  padding: 12px 0 12px;

  @media (max-width: 750px) {
    margin-bottom: 12px;
  }
  @media (min-width: 751px) and (max-width: 1100px) {
    max-width:430px;
  }
  @media(min-width:476px)and (max-width:750px){
    width:calc(100% - 40px);
    max-width:710px;
    padding-right:10px
  }
`;

export const Wrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #181818;
  height: 48px;
  grid-column: 1;
  max-width: 430px;

  ${({ isFirst }) =>
    isFirst &&
    css`
      border-top: 1px solid #181818;
      gap: 24px;
    `}
`;
export const Wrapper2 = styled.span`
  display: flex;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid #181818;
  grid-column: 1;
  max-width: 430px;

  ${({ isFirst }) =>
    isFirst &&
    css`
      border-top: 1px solid #181818;
    `}
`;
export const Counter = styled.p`
  padding: 12px 0;
  margin-left: -1px;
  color: #474945;
`;

export const Details = styled.div`
  color: #474945;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;

  @media (max-width: 750px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const PictureThree = styled.div`
  background-image: url(${ThirdPicture});
  max-width: 425px;
  width: 100%;
  height: 238px;
  padding-top: 110px;
  background-repeat: no-repeat;
  background-position: bottom;
  aspect-ratio: 2 / 1;

  @media (max-width: 750px) {
    width: 320px;
    height: 200px;
    background-image: url(${ThirdPictureSmall});
    padding-top: 48px;
  }
  @media (min-width: 476px) and (max-width: 750px) {
      background-size: contain;
      
      width: 100% - 40px;
      aspect-ratio: 16/9;
      min-width: 436px;
      height: auto;
    }
`;

export const StyledP = styled.p`
  font-size: 124px;
  line-height: 113px;
  color: #181818;
  margin: 44px 0;

  @media (max-width: 750px) {
    font-size: 69px;
    line-height: 91px;
    margin: 2px 0 8px;
  }
  @media (min-width:751px)and (max-width:910px){
    font-size: 108px;
  }
`;

export const PictureFour = styled.div`
  background-image: url(${FourthPicture});
  background-repeat: no-repeat;
  max-width: 427px;
  width: 100%;
  height: 569px;
  margin-left: 120px;
  margin-top: calc(170px + 48px);

  @media (max-width: 750px) {
    background-image: url(${FourthPictureSmall});
    margin: 48px 0 0 0;
    width: 320px;
    height: 425px;
  }
  @media (min-width: 751px) and (max-width: 1100px) {
    margin-left:0;
    background-size: contain;
    max-width: 500px;
  }
`;

export const LocalWrap = styled.div`
  margin-left: 103px;
  max-width: 430px;
  width: 100%;

  @media (max-width: 750px) {
    margin: 0;
  }
  @media (min-width: 751px) and (max-width: 1100px) {
    margin-left:0px;
  }
`;

export const LocalButton = styled.button`
  padding: 17px 25px 18px;
  max-width: 218px;
  width: 100%;
  height: 60px;
  margin-top: 60px;
  border-radius: 43px;
  background-color: #181818;
  color: #fafafa;
  border: 1px solid #181818;
  gap: 8px;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  text-wrap: nowrap;
  @media (max-width: 750px) {
    width: 201px;
    font-size: 14px;
    line-height: 21px;
    margin: 45px 0 0 60px;
  }

  &:hover {
    cursor: pointer;
    background-color: #2c2c2c;
  }

  &:active {
    background-color: #f5f5f5;
    color: #181818;
  }
  @media (min-width: 476px) and (max-width: 750px) {
    margin: 45px 0 0 0;
  }

`;

export const StyledVectorRight = styled(VectorRight)`
  padding-left: 12px;
  
`;

export const PictureFive = styled.div`
  max-width: 1400px;
  // width: 100%;
  max-height: 524px;
  margin-top: 105px;
  aspect-ratio: 1400 / 524;
  background-image: url(${FifthPicture});
  width: calc(100% - 40px);
  background-size:contain;
  background-repeat: no-repeat;
  
  @media (max-width: 475px) {
    background-image: url(${FifthPictureSmall});
    background-repeat: no-repeat;
    width: 320px;
    height: 400px;
    margin-top: 60px;
    margin-left:0;
    // margin-right:20px;
  }
  @media (min-width: 476px) and (max-width: 750px) {
    width: calc(100% - 40px);
    margin-top: 60px;
  }
  
`;
